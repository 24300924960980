var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "calculator-border" }, [
    _c("div", { staticClass: "calculator-display" }, [
      _c("div", { staticClass: "calculator-formula" }, [
        _vm._v(_vm._s(_vm.formula)),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "calculator-result" }, [
        _vm._v(_vm._s(_vm.result)),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "calculator-items" }, [
      _c("div", { staticClass: "calculator-row-1" }, [
        _c(
          "div",
          {
            staticClass: "calculator-item",
            on: {
              click: function ($event) {
                return _vm.drop()
              },
            },
          },
          [_vm._v("←")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "calculator-item",
            on: {
              click: function ($event) {
                return _vm.cleanResult()
              },
            },
          },
          [_vm._v("CE")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "calculator-item",
            on: {
              click: function ($event) {
                return _vm.cleanAll()
              },
            },
          },
          [_vm._v("C")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "calculator-item",
            on: {
              click: function ($event) {
                return _vm.toggle()
              },
            },
          },
          [_vm._v("±")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "calculator-item",
            on: {
              click: function ($event) {
                return _vm.square()
              },
            },
          },
          [_vm._v("√")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "calculator-row-2" }, [
        _c(
          "div",
          {
            staticClass: "calculator-item",
            on: {
              click: function ($event) {
                return _vm.operate(7)
              },
            },
          },
          [_vm._v("7")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "calculator-item",
            on: {
              click: function ($event) {
                return _vm.operate(8)
              },
            },
          },
          [_vm._v("8")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "calculator-item",
            on: {
              click: function ($event) {
                return _vm.operate(9)
              },
            },
          },
          [_vm._v("9")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "calculator-item",
            on: {
              click: function ($event) {
                return _vm.operate("/")
              },
            },
          },
          [_vm._v("/")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "calculator-item",
            on: {
              click: function ($event) {
                return _vm.operate("%")
              },
            },
          },
          [_vm._v("%")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "calculator-row-3" }, [
        _c(
          "div",
          {
            staticClass: "calculator-item",
            on: {
              click: function ($event) {
                return _vm.operate(4)
              },
            },
          },
          [_vm._v("4")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "calculator-item",
            on: {
              click: function ($event) {
                return _vm.operate(5)
              },
            },
          },
          [_vm._v("5")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "calculator-item",
            on: {
              click: function ($event) {
                return _vm.operate(6)
              },
            },
          },
          [_vm._v("6")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "calculator-item",
            on: {
              click: function ($event) {
                return _vm.operate("*")
              },
            },
          },
          [_vm._v("*")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "calculator-item",
            on: {
              click: function ($event) {
                return _vm.devided()
              },
            },
          },
          [_vm._v("1/x")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "calculator-row-4" }, [
        _c(
          "div",
          {
            staticClass: "calculator-item",
            on: {
              click: function ($event) {
                return _vm.operate(1)
              },
            },
          },
          [_vm._v("1")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "calculator-item",
            on: {
              click: function ($event) {
                return _vm.operate(2)
              },
            },
          },
          [_vm._v("2")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "calculator-item",
            on: {
              click: function ($event) {
                return _vm.operate(3)
              },
            },
          },
          [_vm._v("3")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "calculator-item",
            on: {
              click: function ($event) {
                return _vm.operate("-")
              },
            },
          },
          [_vm._v("-")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "calculator-item",
            on: {
              click: function ($event) {
                return _vm.operate("+")
              },
            },
          },
          [_vm._v("+")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "calculator-row-5" }, [
        _c(
          "div",
          {
            staticClass: "calculator-item",
            on: {
              click: function ($event) {
                return _vm.operate(0)
              },
            },
          },
          [_vm._v("0")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "calculator-item",
            on: {
              click: function ($event) {
                return _vm.operate(".")
              },
            },
          },
          [_vm._v(".")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "calculator-item",
            on: {
              click: function ($event) {
                return _vm.equal()
              },
            },
          },
          [_vm._v("=")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }