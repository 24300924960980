var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "d-flex flex-column-fluid" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "card card-custom gutter-b bg-white border-0",
                  },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _c("form", [
                        _c("div", { staticClass: "form-group row" }, [
                          _c("div", { staticClass: "col-md-6" }, [
                            _c("label", { staticClass: "text-body" }, [
                              _vm._v("Warehouses From"),
                            ]),
                            _vm._v(" "),
                            _c("fieldset", { staticClass: "form-group mb-3" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.stocktransfer.warehouse_from,
                                      expression:
                                        "stocktransfer.warehouse_from",
                                    },
                                  ],
                                  staticClass:
                                    "js-states form-control bg-transparent",
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.stocktransfer,
                                        "warehouse_from",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v("Select Warehouse From"),
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(_vm.warehouses, function (warehouse) {
                                    return _c(
                                      "option",
                                      {
                                        domProps: {
                                          value: warehouse.warehouse_id,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                              " +
                                            _vm._s(warehouse.warehouse_name) +
                                            "\n                            "
                                        ),
                                      ]
                                    )
                                  }),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _vm.errors.has("warehouse_from")
                                ? _c("small", {
                                    staticClass: "form-text text-danger",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.errors.get("warehouse_from")
                                      ),
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-6" }, [
                            _c("label", { staticClass: "text-body" }, [
                              _vm._v("Warehouses To"),
                            ]),
                            _vm._v(" "),
                            _c("fieldset", { staticClass: "form-group mb-3" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.stocktransfer.warehouse_to,
                                      expression: "stocktransfer.warehouse_to",
                                    },
                                  ],
                                  staticClass:
                                    "js-states form-control bg-transparent",
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.stocktransfer,
                                        "warehouse_to",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v("Select Warehouse To"),
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(_vm.warehouses, function (warehouse) {
                                    return _c(
                                      "option",
                                      {
                                        domProps: {
                                          value: warehouse.warehouse_id,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                              " +
                                            _vm._s(warehouse.warehouse_name) +
                                            "\n                            "
                                        ),
                                      ]
                                    )
                                  }),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _vm.errors.has("warehouse_to")
                                ? _c("small", {
                                    staticClass: "form-text text-danger",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.errors.get("warehouse_to")
                                      ),
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-6" }, [
                            _c("label", { staticClass: "text-body" }, [
                              _vm._v("Refrence No"),
                            ]),
                            _vm._v(" "),
                            _c("fieldset", { staticClass: "form-group mb-3" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.stocktransfer.reference_no,
                                    expression: "stocktransfer.reference_no",
                                  },
                                ],
                                staticClass: "form-control bg-white",
                                attrs: {
                                  type: "text",
                                  "aria-describedby": "textHelp",
                                  value: "",
                                },
                                domProps: {
                                  value: _vm.stocktransfer.reference_no,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.stocktransfer,
                                      "reference_no",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _vm.errors.has("reference_no")
                              ? _c("small", {
                                  staticClass: "form-text text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.get("reference_no")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-6" }, [
                            _c("label", { staticClass: "text-body" }, [
                              _vm._v("Stock Transfer Date"),
                            ]),
                            _vm._v(" "),
                            _c("fieldset", { staticClass: "form-group mb-3" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.stocktransfer.trasfer_date,
                                    expression: "stocktransfer.trasfer_date",
                                  },
                                ],
                                staticClass: "form-control bg-white",
                                attrs: {
                                  type: "date",
                                  "aria-describedby": "textHelp",
                                  value: "",
                                },
                                domProps: {
                                  value: _vm.stocktransfer.trasfer_date,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.stocktransfer,
                                      "trasfer_date",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _vm.errors.has("trasfer_date")
                              ? _c("small", {
                                  staticClass: "form-text text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.get("trasfer_date")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "card card-custom gutter-b bg-white border-0",
                  },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "col-md-12" }, [
                        _c("label", { staticClass: "text-body" }, [
                          _vm._v("Products"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "fieldset",
                          { staticClass: "form-group mb-3 d-flex" },
                          [
                            _c("multiselect", {
                              attrs: {
                                options: _vm.products,
                                "custom-label": _vm.nameWithLang,
                                placeholder: "Select Product",
                                label: "name",
                                "track-by": "product_id",
                                multiple: true,
                                taggable: true,
                              },
                              on: {
                                select: _vm.addProduct,
                                remove: _vm.removeItem,
                              },
                              model: {
                                value: _vm.product_id,
                                callback: function ($$v) {
                                  _vm.product_id = $$v
                                },
                                expression: "product_id",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.errors.has("product_id")
                          ? _c("small", {
                              staticClass: "form-text text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.errors.get("product_id")
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _vm.combination_products.length > 0
                        ? _c("div", { staticClass: "col-md-12" }, [
                            _c("label", { staticClass: "text-body" }, [
                              _vm._v("Select Variation Product"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "fieldset",
                              { staticClass: "form-group mb-3 d-flex" },
                              [
                                _c("multiselect", {
                                  attrs: {
                                    options: _vm.combination_products,
                                    placeholder: "Select Product",
                                    label: "title",
                                    "track-by": "product_combination_id",
                                    multiple: true,
                                    taggable: true,
                                  },
                                  on: {
                                    select: _vm.addCombinationProduct,
                                    remove: _vm.removeItem,
                                  },
                                  model: {
                                    value: _vm.combination_product_id,
                                    callback: function ($$v) {
                                      _vm.combination_product_id = $$v
                                    },
                                    expression: "combination_product_id",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.errors.has("product_id")
                              ? _c("small", {
                                  staticClass: "form-text text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.get("product_id")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.display_table
                        ? _c("div", { staticClass: "col-12" }, [
                            _c("div", { staticClass: "table-responsive" }, [
                              _c(
                                "table",
                                {
                                  staticClass: "table table-striped text-body",
                                },
                                [
                                  _vm._m(1),
                                  _vm._v(" "),
                                  _c(
                                    "tbody",
                                    [
                                      _vm._l(
                                        _vm.selectedProducts,
                                        function (selectedProduct, index) {
                                          return _c("tr", {}, [
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(selectedProduct.title)
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { staticClass: "text-center" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.stocktransfer.qty[
                                                          index
                                                        ],
                                                      expression:
                                                        "stocktransfer.qty[index]",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    type: "text",
                                                    placeholder:
                                                      "Enter Quantity",
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.stocktransfer.qty[
                                                        index
                                                      ],
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.setStockTransfer(
                                                        index,
                                                        selectedProduct
                                                      )
                                                    },
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.stocktransfer.qty,
                                                        index,
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _vm.errors.has("qty." + index)
                                                  ? _c("small", {
                                                      staticClass:
                                                        "form-text text-danger",
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.errors.get(
                                                            "qty." + index
                                                          )
                                                        ),
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              attrs: {
                                                type: "hidden",
                                                set: (_vm.stocktransfer.product_id[
                                                  index
                                                ] = selectedProduct.product_id),
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("input", {
                                              attrs: {
                                                type: "hidden",
                                                set: (_vm.stocktransfer.product_combination_id[
                                                  index
                                                ] =
                                                  selectedProduct.product_combination_id),
                                              },
                                            }),
                                          ])
                                        }
                                      ),
                                      _vm._v(" "),
                                      _c("tr", [
                                        _c("td"),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm.errors.has("qty")
                                            ? _c("small", {
                                                staticClass:
                                                  "form-text text-danger",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.errors.get("qty")
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                        ]),
                                      ]),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "card card-custom gutter-b bg-white border-0",
                  },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "row mb-3" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("label", [_vm._v("Note")]),
                          _vm._v(" "),
                          _c("fieldset", { staticClass: "form-group mb-3" }, [
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.stocktransfer.notes,
                                  expression: "stocktransfer.notes",
                                },
                              ],
                              staticClass: "form-control",
                              staticStyle: { height: "130px" },
                              attrs: {
                                id: "label-textarea",
                                rows: "6",
                                name: "notes",
                                placeholder: "Please add some note",
                                spellcheck: "false",
                              },
                              domProps: { value: _vm.stocktransfer.notes },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.stocktransfer,
                                    "notes",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _vm.errors.has("notes")
                            ? _c("small", {
                                staticClass: "form-text text-danger",
                                domProps: {
                                  textContent: _vm._s(_vm.errors.get("notes")),
                                },
                              })
                            : _vm._e(),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-12" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            on: {
                              click: function ($event) {
                                return _vm.addstocktransfer()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                      Submit\n                    "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
        _c(
          "div",
          {
            staticClass:
              "card card-custom gutter-b bg-transparent shadow-none border-0",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "card-header align-items-center border-bottom-dark px-0",
              },
              [
                _c("div", { staticClass: "card-title mb-0" }, [
                  _c(
                    "h3",
                    {
                      staticClass: "card-label mb-0 font-weight-bold text-body",
                    },
                    [
                      _vm._v(
                        "\n                      Stock Transfer\n                    "
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c(
          "th",
          { staticClass: "border-0 header-heading", attrs: { scope: "col" } },
          [
            _vm._v(
              "\n                              Name\n                            "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "th",
          { staticClass: "border-0 header-heading", attrs: { scope: "col" } },
          [
            _vm._v(
              "\n                              Quantity\n                            "
            ),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }