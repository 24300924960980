var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group row" }, [
    _c("div", { staticClass: "col-md-12" }, [
      _c("label", [_vm._v("Forget Email Template")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.emailsetting.forget_email_template,
              expression: "emailsetting.forget_email_template",
            },
          ],
          staticClass: "form-control border-dark",
          attrs: { type: "text", cols: "12", rows: "12" },
          domProps: { value: _vm.emailsetting.forget_email_template },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(
                _vm.emailsetting,
                "forget_email_template",
                $event.target.value
              )
            },
          },
        }),
        _vm._v(" "),
        _vm.errors.has("body")
          ? _c("small", {
              staticClass: "form-text text-danger",
              domProps: { textContent: _vm._s(_vm.errors.get("body")) },
            })
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-12" }, [
      _c("label", [_vm._v("Contact Us Email Template")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.emailsetting.contact_us_email_template,
              expression: "emailsetting.contact_us_email_template",
            },
          ],
          staticClass: "form-control border-dark",
          attrs: { type: "text", cols: "12", rows: "12" },
          domProps: { value: _vm.emailsetting.contact_us_email_template },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(
                _vm.emailsetting,
                "contact_us_email_template",
                $event.target.value
              )
            },
          },
        }),
        _vm._v(" "),
        _vm.errors.has("body")
          ? _c("small", {
              staticClass: "form-text text-danger",
              domProps: { textContent: _vm._s(_vm.errors.get("body")) },
            })
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-12" }, [
      _c("label", [_vm._v("Welcome Email Template")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.emailsetting.welcome_email_template,
              expression: "emailsetting.welcome_email_template",
            },
          ],
          staticClass: "form-control border-dark",
          attrs: { type: "text", cols: "12", rows: "12" },
          domProps: { value: _vm.emailsetting.welcome_email_template },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(
                _vm.emailsetting,
                "welcome_email_template",
                $event.target.value
              )
            },
          },
        }),
        _vm._v(" "),
        _vm.errors.has("body")
          ? _c("small", {
              staticClass: "form-text text-danger",
              domProps: { textContent: _vm._s(_vm.errors.get("body")) },
            })
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-12" }, [
      _c("label", [_vm._v("Order Email Template")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.emailsetting.order_email_template,
              expression: "emailsetting.order_email_template",
            },
          ],
          staticClass: "form-control border-dark",
          attrs: { type: "text", cols: "12", rows: "12" },
          domProps: { value: _vm.emailsetting.order_email_template },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(
                _vm.emailsetting,
                "order_email_template",
                $event.target.value
              )
            },
          },
        }),
        _vm._v(" "),
        _vm.errors.has("body")
          ? _c("small", {
              staticClass: "form-text text-danger",
              domProps: { textContent: _vm._s(_vm.errors.get("body")) },
            })
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-12" }, [
      _c("br"),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { type: "submit" },
          on: {
            click: function ($event) {
              return _vm.updateSetting(_vm.emailsetting)
            },
          },
        },
        [_vm._v("Submit")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }