var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "notfound" } }, [
    _c(
      "div",
      { staticClass: "notfound" },
      [
        _vm._m(0),
        _vm._v(" "),
        _c("p", [_vm._v("You Don't Have Permission To Access This Module")]),
        _vm._v(" "),
        _c("router-link", { attrs: { to: "/admin/dashboard" } }, [
          _vm._v("home page"),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "notfound-403" }, [
      _c("h1", [
        _vm._v("A"),
        _c("span", [_vm._v("cc")]),
        _vm._v("ess D"),
        _c("span", [_vm._v("e")]),
        _vm._v("ni"),
        _c("span", [_vm._v("e")]),
        _vm._v("d"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }