var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "footer bg-white py-4 d-flex flex-lg-column",
        attrs: { id: "tc_footer" },
      },
      [
        _c(
          "div",
          {
            staticClass:
              "container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between",
          },
          [
            _c("div", { staticClass: "text-dark order-2 order-md-1" }, [
              _c("span", { staticClass: "text-muted font-weight-bold mr-2" }, [
                _vm._v("2020©"),
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "text-dark-75 text-hover-primary",
                  attrs: { href: "#", target: "_blank" },
                },
                [_vm._v("Themescoder")]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "nav nav-dark" }, [
              _c(
                "a",
                {
                  staticClass: "nav-link pl-0 pr-5",
                  attrs: { href: "#", target: "_blank" },
                },
                [_vm._v("About")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "nav-link pl-0 pr-5",
                  attrs: { href: "#c", target: "_blank" },
                },
                [_vm._v("Team")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "nav-link pl-0 pr-0",
                  attrs: { href: "#", target: "_blank" },
                },
                [_vm._v("Contact")]
              ),
            ]),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }