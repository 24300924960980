var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group row" }, [
    _c("div", { staticClass: "col-md-12" }, [
      _c("label", [_vm._v("Large Height")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.gallary_setting.large_height,
              expression: "gallary_setting.large_height",
            },
          ],
          ref: "large_height",
          staticClass: "form-control border-dark",
          attrs: { type: "number", placeholder: "" },
          domProps: { value: _vm.gallary_setting.large_height },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.gallary_setting, "large_height", $event.target.value)
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-12" }, [
      _c("label", [_vm._v("Large Width")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.gallary_setting.large_width,
              expression: "gallary_setting.large_width",
            },
          ],
          ref: "large_width",
          staticClass: "form-control border-dark",
          attrs: { type: "number", placeholder: "" },
          domProps: { value: _vm.gallary_setting.large_width },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.gallary_setting, "large_width", $event.target.value)
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-12" }, [
      _c("br"),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { type: "submit" },
          on: {
            click: function ($event) {
              return _vm.updateSetting()
            },
          },
        },
        [_vm._v("Submit")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }