var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group row" }, [
    _c("ul", { staticClass: "list-unstyled mb-0 login-forms" }, [
      _c("li", { staticClass: "mr-2 mb-1" }, [
        _c("fieldset", [
          _c("div", { staticClass: "custom-control custom-checkbox" }, [
            _c("input", {
              staticClass: "custom-control-input",
              attrs: {
                type: "checkbox",
                name: "login_signup_form",
                id: "webloginsignup1",
              },
              domProps: {
                checked:
                  _vm.apploginsignup.login_signup_form == "1" ? "checked" : "",
              },
              on: {
                change: function ($event) {
                  return _vm.check($event)
                },
              },
            }),
            _c(
              "label",
              {
                staticClass: "custom-control-label",
                attrs: { for: "webloginsignup1" },
              },
              [_vm._v("Login/Signup Form")]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "mr-2 mb-1" }, [
        _c("fieldset", [
          _c("div", { staticClass: "custom-control custom-checkbox" }, [
            _c("input", {
              staticClass: "custom-control-input",
              attrs: {
                type: "checkbox",
                name: "authenticate_with_email_password",
                id: "webloginsignup2",
              },
              domProps: {
                checked:
                  _vm.apploginsignup.authenticate_with_email_password == "1"
                    ? "checked"
                    : "",
              },
              on: {
                change: function ($event) {
                  return _vm.check($event)
                },
              },
            }),
            _c(
              "label",
              {
                staticClass: "custom-control-label",
                attrs: { for: "webloginsignup2" },
              },
              [_vm._v("Email")]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "mr-2 mb-1" }, [
        _c("fieldset", [
          _c("div", { staticClass: "custom-control custom-checkbox" }, [
            _c("input", {
              staticClass: "custom-control-input",
              attrs: {
                type: "checkbox",
                name: "authenticate_with_phone",
                id: "webloginsignup3",
              },
              domProps: {
                checked:
                  _vm.apploginsignup.authenticate_with_phone == "1"
                    ? "checked"
                    : "",
              },
              on: {
                change: function ($event) {
                  return _vm.check($event)
                },
              },
            }),
            _c(
              "label",
              {
                staticClass: "custom-control-label",
                attrs: { for: "webloginsignup3" },
              },
              [_vm._v("Phone")]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "mr-2 mb-1" }, [
        _c("fieldset", [
          _c("div", { staticClass: "custom-control custom-checkbox" }, [
            _c("input", {
              staticClass: "custom-control-input",
              attrs: {
                type: "checkbox",
                name: "authenticate_with_facebook",
                id: "webloginsignup4",
              },
              domProps: {
                checked:
                  _vm.apploginsignup.authenticate_with_facebook == "1"
                    ? "checked"
                    : "",
              },
              on: {
                change: function ($event) {
                  return _vm.check($event)
                },
              },
            }),
            _c(
              "label",
              {
                staticClass: "custom-control-label",
                attrs: { for: "webloginsignup4" },
              },
              [_vm._v("Facebook")]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "mr-2 mb-1" }, [
        _c("fieldset", [
          _c("div", { staticClass: "custom-control custom-checkbox" }, [
            _c("input", {
              staticClass: "custom-control-input",
              attrs: {
                type: "checkbox",
                name: "authenticate_with_google",
                id: "webloginsignup5",
              },
              domProps: {
                checked:
                  _vm.apploginsignup.authenticate_with_google == "1"
                    ? "checked"
                    : "",
              },
              on: {
                change: function ($event) {
                  return _vm.check($event)
                },
              },
            }),
            _c(
              "label",
              {
                staticClass: "custom-control-label",
                attrs: { for: "webloginsignup5" },
              },
              [_vm._v("Google")]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "mr-2 mb-1" }, [
        _c("fieldset", [
          _c("div", { staticClass: "custom-control custom-checkbox" }, [
            _c("input", {
              staticClass: "custom-control-input",
              attrs: {
                type: "checkbox",
                name: "authenticate_with_guest_checkout",
                id: "webloginsignup6",
              },
              domProps: {
                checked:
                  _vm.apploginsignup.authenticate_with_guest_checkout == "1"
                    ? "checked"
                    : "",
              },
              on: {
                change: function ($event) {
                  return _vm.check($event)
                },
              },
            }),
            _c(
              "label",
              {
                staticClass: "custom-control-label",
                attrs: { for: "webloginsignup6" },
              },
              [_vm._v("Guest Checkout")]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-12" }, [
        _c("br"),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "submit" },
            on: {
              click: function ($event) {
                return _vm.updateSetting()
              },
            },
          },
          [_vm._v("Submit")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }